.leaflet-container {
    float: right;
    position: relative;
    margin-top: 0%;
    height: 600px !important;
    width: 85% !important;
}

.leaflet-marker-pane img {
    background: transparent !important;
}

.MapContainerCss {
    display: flex;
    justify-content: center;
}

@media only screen and (min-width:0px) and (max-width: 600px) {
    .footer {
        position: absolute;
        margin-top: 10%;
    }
}