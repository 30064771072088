.WhoAreWe {
    /* border: 1px solid green; */
    position: relative;
    /* top: 1000px; */
    // top: 265%;
    width: 100%;

    padding-top: 20px;
    padding-bottom: 40px;

    .img-container {
        margin: 0px;
        padding: 0px;

        img {
            margin-right: 0px !important;
            margin-left: 0px !important;
            width: 97%;
            height: 100%;
        }
    }

    .content-wrapper {
        padding: 9%;
        // display: flex;
        // justify-content: center;
        // align-items: center;

        h1 {
            // font-size: 49px;
            margin-bottom: 29px;
        }

        p {
            font-size: 16px;
            padding-right: 45px;
            line-height: 30px;
            margin-bottom: 32px;
        }

        a {
            margin-top: 20px;
            font-size: 16px;
        }
    }

    .WhoAreWe-Img-Row {
        margin-top: 80px !important;
    }

    .WhoAreWe-Img-Content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 50px;

        p {
            color: #000047;
            font-family: newPoppins;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            padding-top: 25px;
            // line-height: 140%;
            // padding-top: 10%;
        }
    }

    @media only screen and (min-width: 1000px) and (max-width: 1490px) {
        .img-container {
            margin: 0px;
            padding: 0px;

            img {
                margin-right: 0px !important;
                margin-left: 0px !important;
                width: 97%;
                height: 90%;
            }
        }

        .content-wrapper {
            padding: 5%;
            // display: flex;
            // justify-content: center;
            // align-items: center;

            h1 {
                // font-size: 49px;
                margin-bottom: 29px;
            }

            p {
                font-size: 16px;
                padding-right: 35px;
                line-height: 30px;
                margin-bottom: 32px;
            }

            a {
                margin-top: 20px;
                font-size: 16px;
            }
        }

        .WhoAreWe-Img-Row {
            margin-top: 50px !important;
        }
    }

    @media only screen and (min-width: 700px) and (max-width: 1000px) {
        .img-container {
            margin: 0px;
            padding: 0px;

            img {
                margin-right: 0px !important;
                margin-left: 0px !important;
                width: 93%;
                height: 93%;
            }
        }

        .content-wrapper {
            padding: 3%;
            // display: flex;
            // justify-content: center;
            // align-items: center;

            h1 {
                // font-size: 49px;
                margin-bottom: 20px;
            }

            p {
                font-size: 16px;
                padding-right: 6px;
                line-height: 30px;
                margin-bottom: 20px;
            }

            a {
                margin-top: 10px;
                font-size: 16px;
            }
        }

        .WhoAreWe-Img-Row {
            margin-top: 0 !important;
        }

        .WhoAreWe-Img-Content {
            p {
                font-size: 17px;
            }
        }
    }

    @media only screen and (max-width: 700px) {
        .WhoAreWe-Img-Row {
            margin-top: 0 !important;
        }
    }
}