.openingtitle {
    font-family: 'newPoppins';
    font-style: normal;
    font-weight: 600;
    font-size: 22px !important;
    line-height: 25px;
    color: #000047;
    margin-left: 9%;
    padding-top: 3%;

}


.openingtitle input {
    // color: red;
    font-size: 14px;
    line-height: 10px;
}
.openingtitlecol2 input {
    font-size: 14px;
    line-height: 10px;
}

.openingtitlexy {
    font-family: 'newPoppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #000047;
    margin-left: 12%;
    padding-top: 3%;
}

.openingtitlexyz {
    font-family: 'newPoppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #000047;
    /* margin-left: 12%; */
    padding-top: 3%;
}


.openingtitlecol2 {
    font-family: 'newPoppins';
    font-style: normal;
    font-weight: 600;
    font-size: 22px !important;
    line-height: 36px;
    color: #000047;
    margin-left: -10%;
    padding-top: 3%;

    @media screen and (max-width: 767px){
        margin-left: 10%;
    }
}

.Contactform{
    .row{
        width: 94% !important;
    }
}

.openingtitlecol2x {
    font-family: 'newPoppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #000047;
    margin-left: 0%;
    padding-top: 3%;
}

.openingtitle1 {
    font-family: 'newPoppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #000047;
    margin-left: 9%;
    padding-top: 1%;
}

.openingtitle1c {
    font-family: 'newPoppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #000047;
    /* margin-left: 9%; */
    padding-top: 1%;
}

.NewContainer {
    margin-left: 50px;
}

.Contactform {
    margin-bottom: 4%;
}

.OpeningImg {
    padding-top: 5%;
}

p.ContactAddress {
    font-family: 'newPoppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #000047;
    padding-top: 13px;
}
.phonenumbertel{
    text-decoration: none !important;
}
p.ContactAddress1 {
    font-family: 'newPoppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #000047;
    padding-top: 0%;
    word-wrap: break-word !important;
    padding: 13px;
    margin-left: -10px;
}

.ContactUs1imgcss {
    height: 85% !important;
}

.EmailContent {
    font-family: 'newPoppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    color: #6A6A79;
    padding-top: 10px;
}

.EmailContent1 {
    font-family: 'newPoppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    color: #6A6A79;
    padding-top: 10px;
}

input.InputBox {
    border: none;
    border-bottom: 2px solid #D5D5DC;
    padding: 0%;
    padding-bottom: 8%;
}

input.InputBox1 {
    border: none;
    border-bottom: 2px solid #D5D5DC;
    width: 85%;
    padding: 0%;
    padding-bottom: 8%;

}

button.InputBox2 {
    font-family: 'newPoppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    width: 100%;
    border: 2px solid #817EFF;
    color: #817EFF;
    border-radius: 10px;
    flex: none;
    order: 0;
    flex-grow: 0;
    // width: 60%;
    width: 133px !important;

    @media screen and (max-width:767px){
        width: 133px !important;
        align-items: center;
    }
}

button.InputBox1 {
    font-family: 'newPoppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    // line-height: 27px;
    width: 100%;
    border: 2px solid gray;
    color: gray;
    border-radius: 10px;
    flex: none;
    order: 0;
    flex-grow: 0;
    // width: 60%;
    width: 133px !important;

    @media screen and (max-width:767px){
        width: 133px !important;
        align-items: center;
    }

    @media screen and (max-width:360px){
        margin-left: 0px !important;
        margin-top: 10px;
    }

    &:hover{
        background-color: gray;
        color: #fff;
    }
}

button.InputBox2:hover {
    font-family: 'newPoppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    width: 100%;
    color: white;
    border: 2px solid #817EFF;
    border-radius: 10px;
    flex: none;
    order: 0;
    flex-grow: 0;
    // width: 60%;
    background-color: #817EFF;
}

@media only screen and (min-width: 0px) and (max-width: 500px) {
    input.InputBox {
        border: none;
        border-bottom: 2px solid #D5D5DC;
        padding: 3%;
    }

    .ServicesTag {
        font-family: "newBelgro";
        font-style: normal;
        font-weight: 600;
        // font-size: 30px;
        line-height: 155%;
        position: relative;
        color: #000047;
        padding-top: 20%;
        padding-left: 3%;
    }
}

@media only screen and (min-width: 0px) and (max-width: 500px) {
    // .contactContainer {
    //     margin-left: 15% !important;
    // }
}


@media only screen and (min-width: 500px) and (max-width: 1500px) {
    // .contactContainer {
    //     margin-left: 0% !important;
    // }
}

// @media only screen and (min-width: 0px) and (max-width: 576px) {
//     .openingtitlecol2 {
//         font-family: 'newPoppins';
//         font-style: normal;
//         font-weight: 600;
//         font-size: 24px;
//         line-height: 36px;
//         color: #000047;
//         margin-left: 10%;
//         padding-top: 3%;

//     }
// }