.Whatwedo {
    margin-top: 40px;
    // position: absolute;
    // border: 1px solid green;
    position: relative;
    // top: 450%;
    width: 100%;

    .accordiantitle {
        font-family: "newPoppins";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 48px;
        color: #817eff;
        padding-left: 4%;
        border: 0px !important;
        display: contents;
    }

    .arrowSpan {
        font-size: 40px;
        margin-right: 20px;
    }

    .nonColor {
        color: #000047 !important;
        border: 0px !important;
        font-family: "newPoppins";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        padding-left: 4%;
        display: contents;
    }

    .hrfaq1 {
        opacity: 0.25;
        border: 1px solid #000047;
        border-radius: 10px;
        background-color: #000047;
        margin: 0 !important;
        margin-left: 30px !important;
        margin-right: 19px !important;
    }

    div[class^="card"] {
        background: #f3f3f6 !important;
        // font-family: newPoppins;
        // font-weight: 600;
        border: none !important;
        margin-top: 10px;
        font-size: 16px !important;
    }

    .card-body {
        margin-left: 70px;
        text-align: justify;
    }

    .Services-img {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 170px;
        margin-top: 100px;

        img {
            width: 90%;
        }
    }

    .ServicesParagraph {
        margin-top: 30px !important;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0;

        p {
            font-size: 16px;
            line-height: 30px;
            padding: 10px;
        }

        .topimg {
            margin-bottom: 10px !important;
        }

        .bottomimg {
            padding: 30px !important;
        }
    }

    h1.ServicesTagServices {
        // font-size: 45px;
        margin-left: 0px;
    }

    .wholecontainer {
        padding-left: 8% !important;
        padding-right: 8% !important;
        // margin-bottom: 200px !important;

    }


    .inner-wrapper {
        .inner-image {
            display: flex;
            justify-content: start;
            align-items: flex-end;

            img {
                margin-top: 144px;
                width: 64%;
                margin-bottom: 50px;
                margin-left: -50%;
            }
        }
    }
}