.mainlogo {
    // padding: 4%;
    // width: 235px;
}

.mainnavbar {
    margin: 0 !important;
    padding: 0 !important;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 120px;

    .mainlogo {
        width: 150px !important;
    }

    img {
        margin-left: 20px;
    }

    .nav {
        margin-left: auto !important;
        margin-right: 50px;
    }
}

.nav a {
    font-size: 12px;
    color: #817eff;
    padding: 0px;
}

.nav-item {
    // border-right: 16px solid #f3f3f6;
    margin-right: 10px;
}

.search {
    margin-top: 13%;
}

.nav-contact {
    border: 2px solid #817eff;
    color: white;
    padding: 2px 20px;
    text-align: center;
    text-decoration: none;
    border-radius: 10px;
    font-weight: 600;
    font-family: "newPoppins";
}

.nav-contact:hover {
    background-color: #817eff;
    color: white !important;
}

.contactbutton,.loginButton {
    padding: 0px 10px;
    text-align: center;
    text-decoration: none;
    border-radius: 10px;
    font-weight: 600;
    font-family: "newPoppins";
    border: 1px solid #817eff;
    color: white;

    &.navactive {
        background-color: #817eff;
        color: white;

        a {
            background-color: #817eff;
            color: white;
        }
    }

}

.contactbutton a,.loginButton a {
    text-decoration: none;
}

.contactbutton:hover,.loginButton:hover {
    background-color: #817eff;
    color: white;
}

.contactbutton:hover a,.loginButton:hover a {
    background-color: #817eff;
    color: white;
}

/* .nav-contact:hover .contactb{
    background-color:#817EFF;
    color: white !important;
}  */
/* .contactb{
    padding: 0 !important;
} */
/* .downarrow {
    width: 30px;
    margin-top: 2%;
} */

.ni a {
    color: black;
}

.navservice {
    border-right: 0px solid #f3f3f6;
}

.navservice a {
    float: right;
}

.ni {
    display: inline-block;
    position: relative;
    color: black;

    &.navactive {
        color: darkblue !important;
        text-decoration: none !important;
        background-color: #eee !important;
        border-bottom: 2px solid black !important;
    }
}

.ni::after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: black;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
    color: black;
}

.ni:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.horizontal-line {
    border: 2px solid #817eff;
    opacity: 0.2;
}

@media only screen and (max-width: 769px) {

    .nav li {
        display: none;
    }

    .contactbutton,.loginButton {
        display: none;
    }

    .HamMenu {
        /* float: right; */
        /* margin-top: -10%; */
        /* margin-right: 4%; */
    }

    .mainlogo {
        width: 150px !important;
    }

    .nav-ham li {
        list-style: none;
        padding-bottom: 10px;
    }

    .HamItems {
        position: absolute;
        display: flex;
        justify-content: flex-end;
        padding-right: 10px;
        width: 40%;
        background-color: rgba(0, 0, 0, 0.6);
        margin-left: 60%;
        box-shadow: 0 2px 3px rgb(0 0 0 / 30%);
        z-index: 99999;
    }

    .nav-ham {
        background: transparent;
        font-size: 12px;
    }

    .nav-ham li {
        background: none;
        color: white;
        border: none;
        position: relative;
        right: 5px;
    }

    .nav-ham li a {
        background: none;
        color: white;
        border: none;
        position: relative;
        right: 5px;
    }
}

@media only screen and (min-width: 769px) {
    .HamMenu {
        display: none;
    }

    .HamItems {
        display: none;
    }
}

button#dropdown-basic.dropdown-toggle.btn.btn-success {
    background: transparent;
    color: black;
    font-size: 20px;
    border: none;
    /* justify-content: center; */
    /* text-align: center; */
    /* padding: 1px 1px 0px 0px; */
    padding: 1px;
    margin-top: 2px;
}

button#dropdown-basic.dropdown-toggle.btn.btn-success:hover {
    background: transparent;
    color: #817eff;
    font-size: 20px;
    border: none;
    /* justify-content: center; */
    /* text-align: center; */
    /* padding: 1px 1px 0px 0px; */
    padding: 1px;
}

.dropdown-toggle::after {
    appearance: none !important;
    display: none !important;
}

.downarrow {
    padding-top: 0px;
    padding-left: 12px;
    background: transparent;
}

@media only screen and (min-width: 1000px) and (max-width: 1490px) {}

@media only screen and (min-width: 700px) and (max-width: 1000px) {}

@media only screen and (max-width: 768px) {
    .mainnavbar {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 75px;
    }

    .HamMenu {
        margin-left: auto;
        margin-right: 10px;
    }
}