.Aboutus {
    position: absolute;
    // border: 1px solid green;
    position: relative;
    // top: 450%;
    width: 100%;

    .Services-img {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 170px;
        margin-top: 100px;

        img {
            width: 90%;
        }
    }

    .wholecontainer {
        padding-left: 8% !important;
        padding-right: 8% !important;
    }

    .inner-wrapper {
        .inner-image {
            display: flex;
            justify-content: start;
            align-items: flex-end;

            img {
                margin-top: 144px;
                width: 64%;
                margin-bottom: 50px;
                margin-left: -50%;
            }
        }
    }

    .ServicesParagraph {
        margin-top: 20px;
        p {
            font-size: 16px;
            line-height: 30px;
            padding: 10px;
        }
    }

    h1.ServicesTagServices {
        // font-size: 45px;
        margin-left: 0px;
    }
}
