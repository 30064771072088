.WhatClientSays {
  margin-bottom: 180px;

  .header {
    margin-bottom: 68px;
    margin-top: 77px;
    width: 100%;
    text-align: center;
  }

  .carousel-indicators {
    // border: 2px solid red !important;
    margin: 0 !important;
    width: 100%;
    margin-bottom: -52px !important;
    // color: #000047 !important;

    button {
      // color: #000047 !important;
      background-color: #817eff !important;
      width: 8px;
      height: 8px;
      border-radius: 50% !important;
    }

    // background-color: #000047 !important;
  }

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    display: none;
    width: 2rem;
    height: 2rem;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% 100%;
  }

  .carousel-control-next,
  .carousel-control-prev {
    display: none;
  }

  .WhatClientSaysCarousel-Main {
    // margin-inline: 0% !important;
    // padding-inline: 0% !important;
    margin-left: 10%;

    span {
      background-color: rgba(0, 0, 0, 0);
    }

    .WhatClientSaysCarousel span:hover {
      background-color: rgba(0, 0, 0, 0);
    }

    .WhatClientSaysCarousel:hover {
      background-color: white;
    }

    .Readmore1 {
      &.hide {
        display: none;
      }

      &.show {
        display: inline !important;
      }
    }

    #readmore {
      font-weight: bold;
      color: #817eff;
      cursor: pointer;

      &:hover {
        color: #817eff;
      }
    }

    #readmore2 {
      font-weight: bold;
      color: #817eff;
      cursor: pointer;

      &:hover {
        color: #817eff;
      }
    }

    #readmore3 {
      font-weight: bold;
      color: #817eff;
      cursor: pointer;

      &:hover {
        color: #817eff;
      }
    }

    #readmore4 {
      font-weight: bold;
      color: #817eff;
      cursor: pointer;

      &:hover {
        color: #817eff;
      }
    }

    #readmore5 {
      font-weight: bold;
      color: #817eff;
      cursor: pointer;

      &:hover {
        color: #817eff;
      }
    }

    #readmore6 {
      font-weight: bold;
      color: #817eff;
      cursor: pointer;

      &:hover {
        color: #817eff;
      }
    }

    #readmore7 {
      font-weight: bold;
      color: #817eff;
      cursor: pointer;

      &:hover {
        color: #817eff;
      }
    }

    #readmore8 {
      font-weight: bold;
      color: #817eff;
      cursor: pointer;

      &:hover {
        color: #817eff;
      }
    }

    #readmore9 {
      font-weight: bold;
      color: #817eff;
      cursor: pointer;

      &:hover {
        color: #817eff;
      }
    }

    #readmore10 {
      font-weight: bold;
      color: #817eff;
      cursor: pointer;

      &:hover {
        color: #817eff;
      }
    }

    .Readmoresub {
      display: contents;

      &.show {
        display: inline !important;
      }
    }

    .wcs-col {
      border: 2px solid #d5d5dc !important;
      border-radius: 3%;
      padding: 0 !important;
      width: 24% !important;
      // margin-inline: 1%;
      margin-left: 1% !important;
      height: 320px;
      background: transparent;

      width: 30% !important;
      margin-left: 2.5% !important;


      @media only screen and (min-width: 700px) and (max-width: 1000px) {
        width: 30% !important;
        height: auto !important;
        // height: 370px !important;
        // border: 1px solid red !important;
      }

      @media only screen and (min-width: 500px) and (max-width: 700px) {
        width: 45% !important;
        // border: 1px solid green !important;
      }

      @media only screen and (max-width: 500px) {
        width: 80% !important;
        margin-left: 10% !important;

        // border: 1px solid blue !important;
      }
    }

    .wcs-des-p1 {
      font-family: "newPoppins";
      font-style: normal;
      font-size: 14px;
      line-height: 170%;
      font-weight: 500;
      color: #000047;
      margin-top: 50px;
      margin-left: 25px;
      margin-right: 20px;
      background: transparent;
      padding-right: 10px;
      text-align: justify;
      margin-bottom: 95px;
    }

    .wcs-des-p2 {
      font-family: "newPoppins";
      font-style: normal;
      font-size: 16px;
      line-height: 160%;
      font-weight: 600;
      position: absolute;
      color: #817eff;
      // top: 83%;
top: 80%;
      margin-left: 23px;
      background: transparent;
    }

    .wcs-des-p23 {
      font-family: "newPoppins";
      font-style: normal;
      font-size: 16px;
      line-height: 160%;
      font-weight: 600;
      color: #817eff;
      position: absolute;
      top: 83%;
      margin-left: 23px;
      background: transparent;
      margin-bottom: 30px;
    }

    .star {
      margin-left: 25px;
      margin-top: 40px;
      position: relative;
      top: 32px !important;
      background: transparent;
      background-color: none;

      .Starcss {
        background: transparent !important;
      }

      img {
        margin-right: 5px;
      }
    }
  }

  @media only screen and (min-width: 1000px) and (max-width: 1490px) {
  }

  @media only screen and (min-width: 700px) and (max-width: 1000px) {
  }

  @media only screen and (max-width: 700px) {
  }
}
