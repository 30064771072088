/* .whwedonew {
  margin-bottom: 90px;
}

.whwedoimg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
} */

/* .ServicesParagraphbusiness {
  margin-top: 155px;
}

.AboutBannerimgcss {
  margin-top: 125px;
} */



/* @media only screen and (min-width: 980px) and (max-width: 1110px) {
  p.ContactAddress1 {
    font-size: 12px !important;
  }
}

@media only screen and (min-width: 1110px) and (max-width: 2000px) {
  p.ContactAddress1 {
    font-size: 14px !important;
  }
} */

/* .ServicesTagpricing {
  font-family: "newBelgro";
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  line-height: 155%;
  position: relative;
  color: #000047;
  padding-left: 22%;
  padding-top: 8%;
}

.ServicesTagteam {
  font-family: "newBelgro";
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  line-height: 155%;
  position: relative;
  color: #000047;
  padding-left: 22%;
  padding-top: 8%;
}

.ServicesTagstory {
  font-family: "newBelgro";
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  line-height: 155%;
  position: relative;
  color: #000047;
  padding-left: 22%;
  padding-top: 20%;
}

.ServicesTagbusiness {
  font-family: "newBelgro";
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  line-height: 155%;
  position: relative;
  color: #000047;
  padding-left: 22%;
  padding-top: 20%;
}

.ServicesTag2 {
  font-family: "newBelgro";
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  line-height: 155%;
  position: relative;
  color: #000047;
  padding-left: 13%;
  padding-top: 12%;
}

.ServicesTag4 {
  font-family: "newBelgro";
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  line-height: 155%;
  position: relative;
  color: #000047;
  padding-left: 15%;
  padding-top: 15%;
}

.ServicesTag45 {
  font-family: "newBelgro";
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  line-height: 155%;
  color: #000047;
  margin-left: 40%;
  left: 50%;
  top: 50%;
  transform: translate(0%, -50%);
  padding-top: 5% !important;
}

.ServicesTag46 {
  font-family: "newBelgro";
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  position: relative;
  color: #000047;
  padding-left: 100px;
  padding-top: 100px;
}

.ServicesTag467 {
  font-family: "newBelgro";
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  line-height: 155%;
  position: relative;
  color: #000047;
  padding-top: 10%;
  left: 7%;
  margin-bottom: 6%;
}

.ServicesTagProject {
  font-family: "newBelgro";
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  line-height: 155%;
  position: relative;
  color: #000047;
  padding-left: 100px;
  padding-bottom: 70px;
  margin-top: 100px
} */

.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.xyz p {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10%;
  font-family: 'newPoppins';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
  color: #000047;
}

/* @media only screen and (max-width: 576px) {

  .ServicesTag2 {
    padding-left: 25%;
  }

  .ServicesTag4 {
    margin-left: 7%;
  }

  .ServicesTag45 {
    margin-left: 0% !important;
    margin-top: 10% !important;
  }

  .ServicesTagProject {
    margin-left: -13%;
  }

  .ServicesTagteam {
    margin-left: -5%;
  }

  .ServicesTagstory {
    margin-left: -5%;
  }

  .ServicesTagbusiness {
    margin-left: -5%;
  }

  .ServicesTagpricing {
    margin-left: -5%;
  }

  .IntroCircle1 {
    width: 25% !important;
  }

  .ServicesParagraphbusiness p {
    padding-left: 18% !important;
  }

  .ServicesParagraph1 p {
    margin-left: 12% !important;
  }

  .ServicesParagraph2 p {
    margin-left: 12% !important;
  }
}

@media only screen and (min-width: 500px) and (max-width:576px) {
  .ServicesTag45 {
    margin-left: 20px !important;
    justify-content: center;
  }
}

.ServicesParagraph {
  margin-left: -8%;
  margin-top: 150px;
}

.ServicesParagraphstory {
  margin-left: -13%;
}

.ServicesParagraphbusiness {
  margin-left: 0% !important;
}

.ServicesParagraphbusiness p {
  font-family: 'newPoppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 170%;
  color: #6A6A79;
  position: relative;
  padding-left: 25%;
  padding-right: 15%;
  word-wrap: normal;
}

.AppointmentImgcss {
  width: 85% !important;
  padding-top: 5%;
}

.WhatWeDo1css {
  width: 80% !important;
}

.WhatWeDo2css {
  width: 70% !important;
  margin-top: 7%;
}

.ServicesParagraph p {
  font-family: 'newPoppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 35px;
  color: #6A6A79;
  position: relative;
  padding-left: 25%;
  padding-right: 13%;
  word-wrap: normal;
}

.ServicesParagraphstory p {
  font-family: 'newPoppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 170%;
  color: #6A6A79;
  position: relative;
  padding-left: 25%;
  padding-right: 15%;
  word-wrap: normal;
}

.ServicesParagraph1 p {
  position: relative;
  padding-right: 15%;
  word-wrap: normal;
  padding-top: 10%;
  font-family: 'newPoppins';
  font-style: normal;
  font-weight: 900;
  font-size: 36px;
  line-height: 54px;
  color: #000047;
  margin-left: 5%;
}

.ServicesParagraph2 p {
  position: relative;
  padding-right: 15%;
  word-wrap: normal;
  padding-top: 6%;
  font-family: 'newPoppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 170%;
  margin-left: 5%;
  font-feature-settings: 'calt' off;
  color: #6A6A79;
}

#para123 {
  margin-top: 18%;
}

#paramiddle {
  padding-top: 1% !important;
}

#para12 {
  margin-top: 0%;
}

#paralast {
  margin-bottom: 10% !important;
  padding-top: 1% !important;
}

.ClientImg1css {
  padding-bottom: 10%;
  padding-top: 5%;
  padding-left: 10%;
}

@media only screen and (max-width: 576px) {
  .ClientImg1css {
    padding-right: 7%;
  }
}

@media only screen and (max-width: 576px) {
  .IconlogoImgcss1 {
    padding-top: 8%;
    margin-left: 30%;
  }

  .IconlogoImgcss2 {
    padding-top: 8%;
    margin-left: 33%;
  }

  .IconlogoImgcss3 {
    padding-top: 8%;
    margin-left: 31%;
  }

  .IconlogoImgcss4 {
    padding-top: 8% !important;
    margin-left: 30% !important;
  }

  .xyz p {
    margin-left: -12%;
  }

  .xyz1 p {
    margin-left: -12%;
  }
}

.IconlogoImgcss1 {
  padding-top: 8%;
  margin-left: 30%;
}

.IconlogoImgcss2 {
  padding-top: 8%;
  margin-left: 33%;
}

.IconlogoImgcss3 {
  padding-top: 8%;
  margin-left: 31%;
}

.IconlogoImgcss4 {
  padding-top: 8%;
  margin-left: 24%;
} */