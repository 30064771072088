#contact-us {
  margin-top: 40px;
  /* margin-left: 25px; */

  .ServicesTag {
    font-family: "newBelgro";
    font-style: normal;
    font-weight: 900;
    // font-size: 40px;
    line-height: 155%;
    position: relative;
    color: #000047;
    margin-left: 10%;
    padding-top: 10%;
  }

  .ServicesTagServices {
    font-family: "newBelgro";
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    line-height: 155%;
    position: relative;
    color: #000047;
    padding-left: 24%;
    padding-top: 20%;
    word-wrap: break-word;
  }

  .ServicesTagoffer {
    font-family: "newBelgro";
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    line-height: 155%;
    position: relative;
    color: #000047;
    margin-left: 21px;
  }

  .contact-details {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #6a6a79;
  }

  @media only screen and (min-width: 576px) and (max-width: 710px) {
    .ServicesTagServices {
      font-size: 24px !important;
    }
  }

  @media only screen and (min-width: 800px) and (max-width: 1000px) {
    .ServicesTagbusiness {
      font-size: 32px !important;
    }
  }

  @media only screen and (min-width: 576px) and (max-width: 800px) {
    .ServicesTagbusiness {
      font-size: 25px !important;
    }
  }
}

.swal2-title {
  position: relative;
  max-width: 100%;
  margin: 0;
  padding: 0.8em 1em 0;
  color: inherit;
  font-size: 1.875em;
  font-weight: 600;
  text-align: center;
  background: #fff;
  text-transform: none;
  word-wrap: break-word;
}
.swal2-html-container {
  z-index: 1;
  justify-content: center;
  margin: 1em 1.6em 0.3em;
  padding: 0;
  overflow: auto;
  color: inherit;
  font-size: 1.125em;
  font-weight: normal;
  line-height: normal;
  text-align: center;
  word-wrap: break-word;
  word-break: break-word;
  background-color: #fff;
}

.contactContainer1 {
  .row {
    width: 100% !important;
  }
}

.openingtitle {
  font-family: "newPoppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #000047;
  margin-left: 9%;
  padding-top: 3%;
  // margin-right: 3px;
}

.openingtitlexy {
  font-family: "newPoppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #000047;
  margin-left: 12%;
  padding-top: 3%;
}

.openingtitlexyz {
  font-family: "newPoppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #000047;
  /* margin-left: 12%; */
  padding-top: 3%;
}

.openingtitlecol2 {
  font-family: "newPoppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #000047;
  margin-left: -10%;
  padding-top: 3%;
}

.openingtitlecol2x {
  font-family: "newPoppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #000047;
  margin-left: 0%;
  padding-top: 3%;
}

.openingtitle1 {
  font-family: "newPoppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #000047;
  margin-left: 9%;
  padding-top: 1%;
  margin-top: 30px !important;
}

.openingtitle1c {
  font-family: "newPoppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #000047;
  /* margin-left: 9%; */
  padding-top: 1%;
}

.NewContainer {
  margin-left: 50px;
}

.Contactform {
  margin-bottom: 4%;
}

.OpeningImg {
  padding-top: 5%;
}

p.ContactAddress {
  font-family: "newPoppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  color: #000047;
  padding-top: 0px;
}

p.ContactAddress1 {
  font-family: "newPoppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  color: #000047;
  padding-top: 0%;
  word-wrap: break-word !important;
}

.ContactUs1imgcss {
  height: 85% !important;
}

.EmailContent {
  font-family: "newPoppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  color: #6a6a79;
  padding-top: 10px;

  .MuiInputBase-root {
    font-family: "newPoppins";
    padding: 28px 0 4px;
    width: 100%;
    font-weight: 600;
    border-bottom: 1px solid #d5d5dc;

    @media screen and (max-width: 767px) {
      width: 80%;
    }
  }
}

.FormErrors {
  font-size: 12px;
  color: #817eff;
  width: 70%;
  font-weight: 600;
  font-family: "newPoppins";
  margin-top: 5px;
  line-height: normal;

  .hide {
    display: none;
  }

  .show {
    display: inline;
  }
}
.EmailContent1 {
  font-family: "newPoppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  color: #6a6a79;
  padding-top: 10px;
}

input.InputBox {
  border: none;
  border-bottom: 2px solid #d5d5dc;
  padding: 0%;
  padding-bottom: 8%;
}

input.InputBox1 {
  border: none;
  border-bottom: 2px solid #d5d5dc;
  width: 85%;
  padding: 0%;
  padding-bottom: 8%;
}

button.InputBox2 {
  font-family: "newPoppins";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 27px;
  width: 100%;
  border: 2px solid #817eff;
  color: #817eff;
  border-radius: 6px;
  flex: none;
  order: 0;
  flex-grow: 0;
  padding: 5px;
  // width: 60%;
}

button.InputBox2:hover {
  font-family: "newPoppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  width: 100%;
  color: white;
  border: 2px solid #817eff;
  border-radius: 10px;
  flex: none;
  order: 0;
  flex-grow: 0;
  width: 60%;
  background-color: #817eff;
}

@media only screen and (min-width: 0px) and (max-width: 500px) {
  input.InputBox {
    border: none;
    border-bottom: 2px solid #d5d5dc;
    padding: 3%;
  }

  .ServicesTag {
    font-family: "newBelgro";
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 155%;
    position: relative;
    color: #000047;
    padding-top: 20%;
    padding-left: 3%;
  }
}

// @media only screen and (min-width: 0px) and (max-width: 500px) {
//     .contactContainer {
//         margin-left: 15% !important;
//     }
// }

@media only screen and (max-width: 767px) {
  .ServicesTag1 {
    text-align: center;
  }

  .contactContainer1 {
    .inner-container {
      p {
        text-align: center;
      }
    }
  }

  .openingtitlecol2 {
    font-family: "newPoppins";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #000047;
    margin-left: 10%;
    padding-top: 3%;
  }

  // .inputbox2{
  //     width: ;
  // }
}

// @media only screen and (min-width: 500px) and (max-width: 1500px) {
//     .contactContainer {
//         margin-left: 0% !important;
//     }
// }

// @media only screen and (min-width: 0px) and (max-width: 576px) {
//     .openingtitlecol2 {
//         font-family: 'newPoppins';
//         font-style: normal;
//         font-weight: 600;
//         font-size: 24px;
//         line-height: 36px;
//         color: #000047;
//         margin-left: 10%;
//         padding-top: 3%;
//     }
// }
