.BookAnAppointment {
  margin-top: 130px;

  border: 0px solid;
  padding: 0 !important;
  background-color: rgba(0, 0, 0, 0);
  display: block !important;
  // margin-bottom: 100px;

  .row {
    // background-color: white;
    padding: 0;
    margin: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0) !important;
    // border: 2px solid red;
  }

  .page-region {
    width: 100% !important;
    background-color: #fff !important;
  }

  iframe {
    background-color: rgba(0, 0, 0, 0) !important;
    width: 100% !important;
    height: 100% !important;
  }

  .bookclass {
    border-radius: 10px !important;
  }

  .card,
  .card-header,
  .card-body {
    background-color: rgba(0, 0, 0, 0) !important;
    border: 0px !important;
    margin-top: 4%;
  }

  .card-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0) !important;

    h1 {
      font-size: 30px !important;
      padding-left: 70px !important;
      padding-top: 30px;
      padding-bottom: 17px !important;
    }
  }

  .card-body {
    h3 {
      padding-left: 70px;
      padding-top: 50px;
      margin-bottom: 30px;
      font-family: "newPoppins";
      font-weight: 600;
      background-color: rgba(0, 0, 0, 0);
    }

    p {
      padding-left: 70px;
      background-color: rgba(0, 0, 0, 0);
      font-size: 16px !important;
      // padding-top: 10px;
      padding-right: 160px;

      @media screen and (max-width: 900px) {
        padding-right: 40px;
      }
    }

    span {
      margin-left: 13px;
      background-color: white;
    }
  }

  .col-md-6 {
    padding: 0px;
    margin: 0px;
  }

  .text-container {
    h1 {
      font-size: 48px;
      background-color: rgba(0, 0, 0, 0);

      @media screen and (max-width: 900px) {
        font-size: 20px !important;
        margin-bottom: 36px;
      }
    }

    h3 {
      font-size: 30px;

      @media screen and (max-width: 900px) {
        font-size: 18px !important;
      }
    }

    p {
      font-size: 25px;

      @media screen and (max-width: 900px) {
        font-size: 12px !important;
      }
    }
  }

  .Cal-wrapper {
    background-color: rgba(0, 0, 0, 0) !important;
    .card {
      // padding: 20px;
      // border-left: 1px solid #f3f3f7 !important;

      .fc-toolbar-chunk {
        h2 {
          font-size: 16px;
        }
      }

      .fc-scrollgrid-sync-inner {
        a {
          color: #000047;
          font-size: 14px;
        }
      }

      .fc-daygrid-day-top {
        a {
          color: #6a6a79;
          font-size: 13px;
        }
      }
    }

    .card-header {
      border-bottom: 0px !important;
    }

    h4 {
      background-color: white;
      font-family: "newPoppins";
      font-weight: 600;
      font-size: 16px !important;
      margin-top: 70px;
      padding-left: 14px;
    }
  }

  .calendly-inline-widget {
    // width: 1000px !important;
    background-color: rgba(0, 0, 0, 0) !important;
    margin-top: -60px;
  }

  .calendly-inline-widget iframe,
  .calendly-badge-widget iframe,
  .calendly-overlay iframe {
    display: inline;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
  }
}
