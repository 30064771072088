.wholecontainer {
    padding-left: 5% !important;
    padding-right: 5% !important;
}

.OurProjects {
    margin-top: 90px;

    h1 {
        margin-left: 10px;
        margin-bottom: 50px;
    }

    .project-heading {
        font-weight: 800;
        color: #000047;
        margin-top: 20px;
        // margin-left: 32px;
    }

    .carousel {
        width: 100% !important;
        position: relative;
        // height: 666px;

        p {
            color: #6a6a79;
            font-weight: 400;
            // margin-left: 10%;
            // margin-left: 32px;
            font-size: 17px;
        }
    }

    .home-carousel {
        position: relative;
        width: auto;
        margin-bottom: 8%;
    }

    .carousel-indicators {
        margin-left: 47% !important;
        width: 9%;
        background: transparent;
        margin-bottom: -3rem !important;

        button {
            width: 10px !important;
            height: 10px !important;
            border-radius: 100%;
            background-color: #817eff !important;
        }
    }

    .carousel-control-prev-icon {
        background-color: transparent;
    }

    .carousel-control-next-icon {
        background-color: transparent;
    }

    .image-project {
        width: 100%;
        // padding: 10%;
        // margin: 0 !important;
    }

    .carousel1-col {
        margin: 0 !important;
        padding: 20px;
    }

    @media only screen and (max-width: 765px) {
        .C1I1 {
            display: none;
        }

        .carousel-indicators {
            margin-left: 0 !important;
            width: 100%;
        }
    }
}