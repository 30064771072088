.saving-calculator{
    /* border: 1px solid green; */
    position: relative;
    /* top: 1000px; */
    display: flex;
    // flex-wrap: wrap;
    // gap: 250px;
    justify-content: space-between;
    width: 100%;
    margin-top: 30px;
    padding-top: 60px !important;
    padding-left: 95px;
    .left{
    .text{
        margin-bottom: 68px;
    
        h1{
      
            font-size: 40px;
            font-weight: 900;
            line-height: 58px;
            text-align: left;
            color: #212529;
           

            
        }
        p{
            font-size: 20px;
            font-weight: 400;
            line-height: 40px;
            text-align: left;
            color: #000000;


        }
    }

        

        .left-properties{
            display: flex;
            flex-wrap: wrap;
            margin-top: 68px;
            // padding-right: 200px;
            flex-direction: column;
            .properties{
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 40px;
              
                gap: 25px;
                .property-manager{
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    width: 233px;
                    border-radius: 30px;
                    height: 117px;
                    background-color: white;

                    p{
                        
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 32px;
                        margin: 15px 14px 0px 15px;
                        text-align: center;
                        color: #000000;
                        background:transparent;

                    }
                    .price{
                        font-family: Poppins;
                        font-size: 32px;
                        display: flex;
                        gap:10px;
                        font-weight: 600;
                        line-height: 64px;
                        margin:0 54px 15px 15px;
                        text-align: left;
                        background-color: transparent;
                        color: #FF0000;
                        span{
                            background: transparent;
                        }
                        
                    }
                    .doller{
                 
                        font-size: 20px;
                        font-weight: 700;
                        margin-top: -5px;
                        // margin: -34px 0 34px 15px;
                       
                        text-align: left;
                        background:transparent;

                    }

                }
                .solace-assurance{
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    width: 233px;
                    border-radius: 30px;
                    height: 117px;
                    background-color: white;

                    p{
                        
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 32px;
                        margin: 15px 14px 0px 15px;
                        text-align: center;
                        color:#817EFF;
                        background:transparent;

                    }
                    .price2{
                        font-family: Poppins;
                        font-size: 32px;
                        font-weight: 600;
                        line-height: 64px;
                        margin:0 54px 15px 15px;
                        text-align: left;
                        display: flex;
                        gap: 10px;
                        background-color: transparent;
                        color: #22B00B;
                        span{
                            background: transparent;
                        }
                        
                    }
                    .doller1{
                 
                        font-size: 20px;
                        font-weight: 700;
                        margin:-5px 0 0px 0px;
                       
                        text-align: left;
                        background:transparent;

                    }

                }
            }
        .slider-container{
          
            // margin-top: 20px;
            p{
        
                font-size: 16px;
                font-weight: 500;
                line-height: 32px;
                text-align: left;
                color: #000000;
                //    margin: 0 0 -5px 0;

            }
            .home-value{
                display: flex;
                margin-top: -20px;
             
                gap: 10px;
                p{
                    font-family: Poppins;
                    font-size: 32px;
                    font-weight: 500;
                    line-height: 64px;
                    text-align: left;
                   

                }
                .sl-value{
                    margin-top: 20px;

                }
               
            }
            .slider {
                -webkit-appearance: none;
                // width: 00%;
                height: 5px;
                background: #a1a0a0;
                outline: none;
                border-radius: 5px;
                transition: 'background 0.3s ease';
              }
              
            
              
              .slider::-webkit-slider-thumb {
                -webkit-appearance: none;
                appearance: none;
                width: 25px;
                height: 25px;
                background-color: rgb(255, 255, 255);
                border: 3px solid #875af0;
                border-radius: 50px;
                cursor: pointer;
                transition: background-color 0.3s, border-color 0.3s;
              }
            .num{
            display: flex;
            margin-top: 5px;
            justify-content: space-between;
            p{
            background: transparent;}
        }
        }
    }
}
    .right-savings{
        display: flex;
        // flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
        
        // z-index: 1; 
        margin-top: 180px ;  
        margin-right: 110px;
      
        background: transparent;
        
        
    .rgt-card{
     
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 1;
        padding: 65px 103px 59px 103px;
       
        
        background-color: white;
        border-radius: 25px;

        // box-shadow: 0 0 10px rgb(204, 204, 204);
        
       
        
    }
    .rgt-dol{
        
        font-size: 24px;
        font-weight: 1000;
        background:transparent;
        
        color: #817EFF;
        // margin-left: -30px;
        margin-top:15px;

    }
    .rgt-value{
        background-color:transparent;
        display: flex;
        gap: 11px;
        font-weight: 1000;

        

    }
    .sav-p{
        
        font-size: 20px;
        font-weight: 600;
        background:transparent;
        color: #000000;
      


    }.rgt-price{
        
        font-size: 54px;
        font-weight: 1000;
        // margin-top: -50px;
        // margin-left: 55px;
        color: #817EFF;
        background:transparent;

    }
    .get-started-btn{
        z-index:2 ;
        width: auto;
        background: transparent;
    }
    button{
 
        padding: 12px 50px;
        background-color: #4FC3F4;
        border: none;
        font-family: "newPoppins";
        font-size: 20px;
        font-weight: 600;
        color: white;
        
        
        margin: -20px 160px 0px 160px;
        border-radius: 10px;
        z-index: 2;
        cursor: pointer;
      



    }
    
    }
      
    .rect-img{
    
        img{
            background: transparent;   
             position: absolute;
            right: 0vw;
            // height: 58vh;
            background: transparent;
    
            top: 26%;
        }
    }
}


@media (max-width:1280px){
  

    .saving-calculator .right-savings{
        margin-right: 1 0px;
        // margin-top: 200px;
    }
  
 
}
@media (max-width:1200px){
  
    .saving-calculator .rect-img img {
        display: none;
  
    }
    .saving-calculator{
        .get-started-btn{
            padding: 0;
            margin: 0;
        }
    }
}
@media (max-width:1180px){
    .saving-calculator .right-savings{
        margin: 0px;
        margin-top: 200px;
    }
 
}
 
@media (max-width:900px){
    .saving-calculator {
        // margin: 0px;
        flex-direction: column;
        .right-savings{
            margin-top: 80px;
        }
        .left-properties{
            padding-right: 130px;
        }
        .right-savings{
            // margin-top: 250px;
            margin-left: -70px;
        }
    }


}
@media(max-width:770px){
    .saving-calculator {
        padding-left: 15px;
    .calculator .left-properties {
        padding-right: 0px;
     
    }}

}
@media(max-width:700px){
    .saving-calculator .right-savings{
        
        margin-left: -20px;
    }
    .left-properties{
        padding-right: 0px;
    }

}
@media(max-width:610px){
    .saving-calculator .right-savings{
        
        margin-left: -30px;
        margin-top: 80px;
        margin-bottom: 80px;
    }
    .saving-calculator{
        .left-properties{
            padding-right: 10px;
        }
        .slider-container{
            padding-right: 10px;
        }
    
    }

}
@media(max-width:570px){
    .saving-calculator .right-savings 
    {
        margin-left: -10px;
        margin-top: 50px;
        margin-bottom: 50px;
        .rgt-card {
        padding: 40px;
    }
    button{
        padding: 8px 20px;
        font-size: 16px;
        margin-left:150px;
    }
    .rgt-price{
        font-size: 28px;
        margin-top: 10px;
    }
    .rgt-dol{
        font-size: 16px;
    }
}
    .saving-calculator .calculator .left-properties {
     
        .properties{
            flex-direction: column;
        }
    }
    .saving-calculator{
        .left-properties{
            padding-right: 10px;
        }}


}

@media(max-width:460px){
    .saving-calculator .right-savings{
        
        margin-left: 0px;
        margin: 50px 0;
        .get-started-btn{
            padding: 0;
            margin: 0;
        }
        button{
            // padding: 10px 10px;
            // font-size: 14px;
            margin: 0;
            margin-top: -20px;
            // margin-left:120px;
        }
        .saving-calculator{
            .left-properties{
                padding-right: 10px;
            }}
        
    }



}
@media(max-width:399px){
    .saving-calculator .right-savings{
        
        
        margin-right: 0;
        padding-left: -25px;    
      
        .rgt-card {
            padding: 25px;
            margin-left: -20px;
        }
        button{
            // padding: 10px 10px;
            font-size: 14px;
            margin: 0;
            margin-top: -20px;
            margin-left:-25px;
           
        }
        .get-started-btn{
            padding: 0;
            margin: 0;
            
        }
    }


}

