@font-face {
    font-family: "newBelgro";
    src: url("../assets/fonts/Belgro.ttf");
}

@font-face {
    font-family: "newPoppins";
    src: url("../assets/fonts/Poppins-Light.ttf");
}

.introduction_page {
    /* border: 2px solid red; */
    // border: 2px solid red;
    margin-top: 70px;
}

.home-image-content {
    display: flex;
    justify-content: center;
    align-items: center;
}

.headline1 {
    font-size: 48px;
    font-family: "newBelgro";
    font-weight: 1000;
    line-height: 65px;
    letter-spacing: 0em;
    text-align: left;
    color: #000047;
    position: absolute;
    background: transparent;
    margin-left: -345px;
    margin-top: -230px;
}

.home-img-left p {
    font-family: "newPoppins";
    font-weight: 400;
    font-size: 16px;
    color: #6a6a79;
    position: absolute;
    background-color: transparent;
    margin-top: -10px;
    padding-bottom: 18%;
    margin-left: -345px;
    padding: 0 !important;
}

.home-image-content-img {
    // width: 550px;
}

// .img2-left-innercontent {
//     position: absolute;
//     margin-left: -380px;
//     z-index: 1;
//     background: transparent;
//     margin-top: 80px;
// }

.property-content {
    position: absolute !important;
    margin-left: 810px;
    margin-top: 285px;
    background: white !important;
    padding: 30px;
    border-radius: 15px !important;

    p {
        background-color: white;
    }

    a {
        // margin-top: 20px;
        font-size: 16px;
    }
}

.IntroCircle1 {
    position: absolute;
    margin-left: 480px;
    margin-top: 340px;
    /* width: 6%; */
    width: 90px;
    height: 90px;
}

@media only screen and (min-width: 850px) and (max-width: 1200px) {
    .IntroCircle1 {
        border: 2px solid red !important;
        // top: 450px !important;
        // left: 650px !important;
        bottom: -30px;
    }
}

/** Safari */
@media not all and (min-resolution: 0.001dpcm) {
    .IntroCircle1 {
        // border: 1px solid green;
        top: 450px;
        left: 650px;
    }
}

.textproperty {
    font-family: "newPoppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 35px;
    color: #6a6a79;
    background: none;
    padding-top: 2%;
    margin: 0%;
}

.textproperty1 {
    font-family: "newPoppins";
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 144%;
    color: #000047;
    background: none;
    margin: 0%;
}





@media only screen and (max-width: 1500px) and (max-height:850px) {
    .IntroCircle1 {
        // border: 2px solid red !important;
        top: 450px !important;
        left: 650px !important;
    }
}

@media only screen and (max-width: 900px) {
    .introduction_page {
        margin-top: 40px;
    }

    .headline1 {
        font-size: 30px;
        font-family: "newBelgro";
        font-weight: 1000;
        line-height: 45px;
        letter-spacing: 0em;
        text-align: left;
        color: #000047;
        position: absolute;
        background: transparent;
        margin-left: -135px;
        margin-top: -230px;
    }

    .home-img-left p {
        font-family: "newPoppins";
        font-weight: 400;
        font-size: 16px;
        color: #6a6a79;
        position: absolute;
        background-color: transparent;
        margin-top: -70px;
        // padding-bottom: 18%;
        margin-left: -135px;
        padding: 0 !important;
    }

    .property-content {
        position: absolute !important;
        margin-left: 470px;
        margin-top: 285px;
        background: white !important;
        padding: 20px;
        border-radius: 15px !important;
    }

    .IntroCircle1 {
        display: none !important;
    }

    .textproperty1 {
        font-family: "newPoppins";
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        // line-height: 144%;
        color: #000047;
        background: none;
        margin: 0%;
    }

    .textproperty {
        font-family: "newPoppins";
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        /* line-height: 35px; */
        color: #6a6a79;
        background: none;
        // padding-top: 2%;
        margin: 0;
    }
}

@media only screen and (max-width: 576px) {}

@media only screen and (max-width: 700px) {
    .row {
        margin: 0 !important;
        padding: 0 !important;
    }

    .home-image-content {
        display: flex !important;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .home-img-left {
        // margin-left: -60px !important;
        margin-left: 30px !important;
        margin-right: auto !important;
    }

    .home-image {
        margin-top: 70px !important;
    }

    .property-content {
        display: none !important;
    }

    .IntroCircle1 {
        display: none !important;
    }

    .home-image-content-img {
        width: 90% !important;
    }

    .home-img-left p {
        margin: 0 !important;
    }

    .headline1 {
        margin: 0 !important;
        position: relative;
    }

    .property-content {
        position: relative !important;
        margin: 0 !important;
        background: white !important;
    }
}

@media only screen and (max-width: 450px) {
    .headline1 {
        font-size: 28px !important;
        line-height: 30px !important;
    }
}

@media only screen and (max-wdith:900px) {
    .IntroCircle1 {
        display: none !important;
        width: 0px;
        height: 0px;
    }
}