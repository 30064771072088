* {
    background-color: #F3F3F6;
}

@font-face {
    font-family: "newBelgro";
    src: url("../assets/fonts/Belgro.ttf");
}

@font-face {
    font-family: "newPoppins";
    src: url("../assets/fonts/Poppins-Light.ttf");
}

body p{
    font-family: "newPoppins" !important;
    color: #6A6A79;
}

.card-header h6{
    font-family: "newPoppins" !important;
    font-weight: 800 !important;
    font-size: 20px !important;
}

.card-footer h3{
    font-family: "newPoppins" !important;
    font-size: 17px !important;
    color: #000047;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 1px !important;
}

.card-footer p{
    font-size: 13px !important;
}

body h1{
    font-family: "newBelgro" !important; 
    // font-size: 45px !important;

    @media only screen and (max-width:800px){
        font-size: 30px !important;
    }
}

body li{
    font-family: "newPoppins" !important;   
}

body li div{
    font-size: 14px !important;
    color: black !important;
    /* font-weight: 500 !important; */
}
