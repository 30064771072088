.loginMain {
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    img{
        background-color: transparent;
    }
    input::placeholder {
      font-family: "Poppins", sans-serif;
      font-size: 16px;
      font-weight: 300;
      line-height: 48.28px;
    //   padding-left: 20px;
      text-align: left;
      color: #a0a0a0;
  
                      }
    .loginLeft {
      height: 100%;
      width: 100%;
      .backgroundImage {
        height: 100%;
        width: 100%;
        background-image: url(../../assets/Images/Hero.webp);
        //   background-repeat: no-repeat;
        //   background-attachment: fixed;
        background-size: cover;
        background-position: center center;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        //   aspect-ratio: 16 / 9;
        .innerContent {
          width: 90%;
          display: flex;
          justify-content: center;
          background-color: transparent;
          align-items: center;
          flex-direction: column;
          gap: 30px;
          .innerTop {
            width: 50%;
            display: flex;
            justify-content: center;
            background-color: transparent;
            align-items: center;
            flex-direction: column;
            gap: 35px;
            margin-left: 50%;
            p{
                background-color: transparent;
            }
          }
          // .innerBottom{
          p {
            width: 100%;
            color: white;
            text-align: center;
            font-size: 28px;
          }
          .borderDiv {
            height: 2px;
            width: 100%;
            background-color: white;
          }
          // }
        }
      }
    }
    .loginRight {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: relative;
      .innerContent {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        text-align: left;
        width: 80%;
        //gap: 25px;
        .inputStyle {
          background: rgba(217, 217, 217, 0.4);
          height: 50px;
          border-radius: 10px;
          outline: none;
          
          width: 80%;
          padding: 4px 18px;
        }
      }
      h2 {
        font-size: 32px;
        font-weight: 500;
        line-height: 48.28px;
        text-align: left;
        color: #000047;
        margin-bottom: 10px;
      }
      p:nth-child(2) {
        font-size: 20px;
        font-weight: 300;
        margin-bottom: 25px;
  
      }
      p:nth-child(5) {
        font-size: 16px;
        font-weight: 500;
        color: rgba(0, 0, 71, 1);
        cursor: pointer;
        margin-bottom: 40px;
  
      }
      p:nth-child(5):hover {
        text-decoration: underline;
      }
      button {
        font-size: 23px;
        font-weight: 700;
        letter-spacing: 3.5px;
        color: white;
        background-color: #817EFF;
        border: none;
        padding: 10px 25px;
        border-radius: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
      }
      button:hover {
        background-color: rgb(129, 126, 255, 1);
      }
    }
  }
  .loginMainImg1 {
    position: absolute;
    bottom: 0;
    right: 0;
    opacity: 0.2;
  }
  .loginRightMainImg1 {
    position: absolute;
    bottom: 95%;
    right: 33%;
    opacity: 0.2;
    transform: rotate(180deg);
  }

//   @media only screen and (max-width:769px) {
//     .loginMain{
//         flex-direction: column;
//     }
//     .loginRight{
//         padding: 100px;
//     }
//   }