.OurTeam {
    position: absolute;
    // border: 1px solid green;
    position: relative;
    // top: 450%;
    width: 100%;

    .Services-img {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 170px;
        margin-top: 100px;

        img {
            width: 90%;
        }
    }

    .wholecontainer {
        padding-left: 8% !important;
        padding-right: 8% !important;

        h1 {
            // font-size: 45px;
            margin-top: 100px;
        }
        p {
            font-size: 16px;
            margin-left: 5px;
        }

        .card {
            padding: 5px;
            margin-top: 30px;
            margin-bottom: -25px;
            background-color: rgba(0, 0, 0, 0);
            border: 0px;

            .card-body {
                // border-bottom: 60px solid red;
                // content: "";
                // bottom: -1px;
                // position: relative;
                img {
                    width: auto;
                    // height: 228px;
                    border-radius: 10px;
                    background: white;
                    // background-color: ;
                    -webkit-clip-path: polygon(0% 0%, 80% 0%, 100% 0%, 100% 80%, 80% 100%, 0% 100%, 0% 86%, 0% 30%);
                    clip-path: polygon(0% 0%, 80% 0%, 100% 0%, 100% 80%, 80% 100%, 0% 100%, 0% 86%, 0% 30%);
                }
            }

            .card-footer {
                background-color: rgba(0, 0, 0, 0);
                border: 0px;
                height: 70px;
                p{
                    margin-left: 0px !important;
                }
            }
        }

        .teamimgcontainer{
            margin-left: -18px !important;
        }
    }

    .inner-wrapper {
        padding-right: 40px !important;
        p {
            font-size: 16px;
            line-height: 30px;
            padding: 10px;
        }
        .inner-image {
            display: flex;
            justify-content: start;
            align-items: flex-end;

            img {
                margin-top: 144px;
                width: 64%;
                margin-bottom: 50px;
                margin-left: -50%;
            }
        }
    }

    .ServicesParagraph {
        margin-top: 60px;
        p {
            font-size: 16px;
            line-height: 30px;
            padding: 10px;
            padding-right: 40px;
        }
    }

    h1.ServicesTagServices {
        // font-size: 45px;
        margin-left: 0px;
    }

    .wholecontainer .card .card-body img {
        width: auto;
        border-radius: 10px;
        clip-path: polygon(0% 0%, 80% 0%, 100% 0%, 100% 80%, 80% 100%, 0% 100%, 0% 86%, 0% 30%);

        height: 200px !important;
    }
}
