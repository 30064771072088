
*{
    background-color: #F3F3F6;
}
.third{
    display: flex;
    margin-top: 6%;
    margin-left: 20%;
    justify-content: center;
    position: relative;
    margin-bottom: 0;
}
.last-card{
    
    width: 789px;

   
    // margin-left: 33vw;
    // height: 722px;

}

.faq-card{
    // margin-top: 50px;
    padding: 40px 38px 60px 204px;
    display: flex;
    margin-bottom: 70px;
    border-radius: 10px;
    background-color: white;
    
    flex-direction: column;
    box-shadow: 0 0 0px rgb(255, 255, 255);


    h1{
   
        font-size: 40px;
        font-weight: 900;
        line-height: 58px;
        text-align: left;
        background-color: white;
        
    }

    .faq{
    display: flex;
    background-color: white;
    cursor: pointer;
    border-bottom: solid 1px rgb(198, 198, 198);
    // gap: 50px;
    justify-content: space-between;

    .question{
        font-family: Poppins;
        font-size: 20px;
        font-weight: 600;
        line-height: 60px;
        text-align: left;
        color:#212529;  
        background-color: white;


    }

    span{
        color: 
        #817EFF;
        margin-top: 20px;
        background-color: white;
        // margin-right:280px;
    }
 
}
}
.answer {
    max-height: 0;
    overflow: hidden;
	overflow: hidden;
    background-color: white;
 

	transition: .5s ease;
    line-height: 25px;
    // font-size: 16px;
   
    p{
        color: black;

        font-size: 14px;
        font-weight: 300;
        line-height: 25.2px;
        text-align: left;
        background-color: white;
        text-align: justify;

    }
  }
  
  .answer.show { 
    max-height: 100px;
    
	transition: .5s ease;
  }
.ans-btn{
    background-color: white;
}
.end-home{
    background: transparent;
    position: absolute;
    top: 15%;
    left: -5vw;
}
#end{
    border: none;
}

@media (max-width:1400px){
    .end-home{
      
        margin-left: -5%;
    }

}
@media (max-width: 1286px){
    .end-home{
        background: transparent;
        position: absolute;
        // margin-top: 11%;
        // margin-left: -5%;
    }
    .last-card{
        
        margin-left: 10vw;    
    }
}
@media (max-width:1195px){
    .third{
        display: flex;
    }

    .end-home{
        // position: relative;
  
        left: -10%;
        img{
        width: 350px;
        }// margin-left:20px;
        
        
    }
    .last-card{
        margin-left: 5vw;
        display: flex;
        justify-content: center;
        .faq-card{
            
            margin-top: 50px;
            padding: 30px 50px 20px 180px ;
            .question{
                font-size: 14px;
                line-height: 15px;
                margin-top: 10px;
            }


        }
        .faq{
            span{
                margin-top: 10px;
            }
        }
    .answer{
        font-size: 12px;
        line-height: 20px;
    }
    }
    .last-card{
        width: auto;
        padding: 0 20px;
        .faq{
        
            p{
   
            font-size: 16px;
       
           
    
        }
}}
    
    }

    @media (max-width:900px){
        .end-home{
            left: -15%;
            img{
                width: 350px;
                

            }
        }

    }

@media (max-width:730px){
    .third{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 0;
        
    }
    .end-home{
        position: relative;
        left: 0;
        img{
            width: 400px;
        }
    }
    
    
.faq-card{
    box-shadow: none;
    padding: 10px;
}
    .last-card{
        width: auto;
        .faq-card{
        // padding: 30px 0px;
        }
        .faq{
       
            .question{
            font-size: 14px;  
            line-height: -10px;         
    
        }
  }}
  .answer{
    font-size: 12px;
    text-align: justify;
    br{
        display: none;
    }
    
}


}
@media (max-width:730px){
    .last-card{
        width: auto;
        padding: 0 20px;
        .faq{
           
            // padding: 5px;
            padding: 20px 0px ;
            p{
   
            font-size: 14px;           
    
        }
    .para{
        font-size: 12px;
    }}}
    .last-card{
        padding: 0px 0px 0 0;
        margin: 15px;

    .faq-card{
        margin-top: 50px;
        padding: 20px 40px ;
        box-shadow: 0 0 10px gainsboro;

    }}


}
@media(max-width: 550px){
    .end-home{
        img{
        width: 400px;
        height: auto;
        // margin-left: -20px;
        }
    }
   
}
@media(max-width: 430px){
    .end-home{
        margin-top: 10%;
        img{
        width: 300px;
        height: auto;
        margin-left: 15px;
        }
        .answer{
            font-size: 10px;
        }
    }
   
}
@media(max-width: 320px){
    .end-home{
        img{
        width: 250px;
        height: auto;
        // margin-left: -20px;
        }
    }
   
}

