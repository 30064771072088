.Supercharge {
    margin-top: 200px;

    @media only screen and (max-width: 700px) {
        margin-top: 70px !important;
    }

    .img2-right-content {
        display: flex;
        justify-content: end;
        align-items: flex-start;
        padding: 0 15px;

        .img2-left-innercontent {
            margin-right: -217px;
            margin-top: 70px;

            h1 {
                font-size: 48px;
                font-weight: 1000;
                text-align: left;
                color: #000047;
                position: relative;
                background: transparent;
                font-family: "newBelgro";
                line-height: 70px;
            }

            p {
                font-family: "newPoppins";
                font-weight: 400;
                font-size: 16px;
                color: #6a6a79;
                position: relative;
                background-color: transparent;
                margin-top: 30px;
                line-height: 28px;
            }

            a {
                margin-top: 20px;
                font-size: 16px;
            }
        }

        img {
            width: 70%;
        }
    }

    @media only screen and (min-width: 1200px) and (max-width: 1490px) {
        .img2-left-innercontent {
            margin-right: -185px !important;
            margin-top: 70px;

            h1 {
                font-size: 42px !important;
            }
        }
    }

    @media only screen and (min-width: 700px) and (max-width: 1200px) {
        .img2-left-innercontent {
            margin-right: -110px !important;
            margin-top: 25px !important;

            h1 {
                font-size: 25px !important;
                line-height: 35px !important;
            }

            p {
                font-size: 12px !important;
                margin-top: 10px !important;
                line-height: 16px !important;
            }
        }
    }

    @media only screen and (max-width: 700px) {
        .img2-right-content {
            display: flex;
            flex-direction: column;
            justify-content: end;
            align-items: flex-start;

            .img2-left-innercontent {

                // margin-right: -217px;
                // margin-top: 70px;
                h1 {
                    font-size: 28px !important;
                    font-weight: 1000;
                    text-align: left;
                    color: #000047;
                    position: relative;
                    background: transparent;
                    font-family: "newBelgro";
                    line-height: 30px !important;
                }

                p {
                    font-family: "newPoppins";
                    font-weight: 400;
                    font-size: 14px !important;
                    color: #6a6a79;
                    position: relative;
                    background-color: transparent;
                    margin-top: 10px !important;
                    line-height: 22px !important;
                }

                a {
                    margin-top: 20px;
                    font-size: 16px;
                }
            }

            img {
                width: 100%;
            }
        }
    }
}