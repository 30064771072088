.footer .footer-text p {
  font-weight: 500;
  margin-bottom: 0px;
  color: #6A6A79;
  font-size: 15px;
}

.footer-text a {
  font-weight: 500;
  margin-bottom: 0px;
  color: #6A6A79;
  text-decoration: none;
}

.footer .footer-text1 p {
  font-weight: 500;
  margin-bottom: 0px;
  color: #6A6A79;
  padding-top: 4%;
}

#px1 {
  /* font-weight: 500;
  margin-bottom: 0px;
  color: #6A6A79;
  font-size: 14px;
  padding-top: 0% !important; */

  text-decoration: none;
  font-family: 'newPoppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000047;

  padding-top: 5px;


}

#px2 {
  /* font-weight: 500;
  margin-bottom: 0px;
  color: #6A6A79;
  font-size: 14px;
  padding-top: 0% !important; */

  text-decoration: none;
  font-family: 'newPoppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000047;

  padding-top: 5px;



}

.footer-text1 a {
  /* text-decoration: none; */
  font-family: 'newPoppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* color: #000047; */
  padding-top: 5px;
}

#px1 a {
  text-decoration: none !important;
}

.footer-row {
  /* margin: 24px 62px 30px 100px !important; */
  padding: 5% !important;
}

.footer-heading {
  color: #817EFF;
  font-weight: 700;
  font-size: 16px;
}

.footer-heading1 {
  padding: 5%;
}

.footer-logo-img {
  padding: 1%;
  width: 150px;
  padding-left: 0 !important;
}

.footer-col-second ul {
  font-weight: 500;
  padding: 0 !important;
  margin-top: 4%;
  list-style: none;
  font-family: 'newPoppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000047;
}

.footer-col-second {
  right: 1%;
}

.footer-col-second li a {
  text-decoration: none;
  font-family: 'newPoppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000047;
}

.socials-icons img {
  width: 25px;
  cursor: pointer;
}

.socials-icons img:hover {  
  animation: shake1 .7s ease;
 transform: scale(1.7);
}
@keyframes shake1 {
  0% {
    transform: rotate(-20deg);
  }

  5% {
    transform: scale(1.1);
    transform: rotate(20deg);
  }

  10% {
    transform: scale(1.2);
    transform: rotate(-20deg);
  }

  15% {
    transform: scale(1.2);
    transform: rotate(20deg);
  }

  20% {
    transform: scale(1.3);
    transform: rotate(0deg);
  }

  100% {
    transform: scale(1.3);
    transform: rotate(0deg);
  }
}


.socials-icons {
  list-style: none;
  /* display: flex; */
  margin: 0px 0px 0px 25px;
  width: 100px;
  gap: 10px;
  position: relative;
  margin-left: 30px;
}

.socials-icons li{
  display: inline;
  margin-right: 10px;
}

/* .socials-icons:after {
  content: attr(data-popuptext);
  border-radius: 3px;
  border: 1px solid #817EFF;
  background-color: #fff;
  opacity: 0;
  top: 0;
  left: 0;
  position: relative;
  transition: 500ms ease;
  white-space: nowrap;
  font-size: 14px;
  max-height: 0;
  width: auto;
  color: #000047;

} */

/* .socials-icons:hover:after {
  opacity: 1;
  top: 1.2em;
  max-height: 200px;
  padding: .4em;
} */

@media screen and (max-width:767px){
  .socials-icons{
    margin: 0px 0px 0px 9px;
  }
}

@media screen and (max-width:400px){
  .socials-icons::after{
    margin: 0px 0px 0px -20px;
  }
}

.follow {
  margin: 20px 62px 15px 18px;
  padding-left: 40px;
  font-size: 16px;
  color: #000047;
  line-height: 24px;
}

@media screen and (max-width:767px){
  .follow{
    margin: 20px 62px 15px 0px;
  }
}

.socials-icons li {
  padding-inline: 1%;
}

.terms p {
  font-size: 15px;
  font-weight: 500;
  float: right;
  font-family: 'newPoppins';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
  text-align: right;
  margin-right: 11% !important;
  color: #6A6A79;
}

.horizontal-line2 {
  left: 5%;
  right: 14%;
  top: 86.58%;
  bottom: 13.42%;
  width: 93%;
  position: relative;
  opacity: 0.2;
  border: 1px solid #817EFF;
}

.copyright {
  padding: 1%;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #6A6A79;

}

@media screen and (max-width:650px){
    .copyright p{
       width: 100% !important;
       float: right !important;
    }
}

.carousel1 {
  position: relative !important;
  margin-left: 4% !important;
  margin-right: 4% !important;
}

.copyright p {
  margin-left: 18%;
}

@media screen and (max-width:767px) {
  .copyright p{
    margin-left: 9%;
  }
}

.copyright2 p {
  position: absolute;
  margin-top: 2%;
  margin-left: 29%;
  font-family: 'newPoppins';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
  text-align: right;
  color: #6A6A79;
}

@media only screen and (max-width:576px) {
  .terms {
    margin-left: 12%;
  }

  .terms p {
   
    margin-top: 6%;
    /* margin-right: 25% !important; */
  }
}

@media only screen and (min-width:500px) and (max-width:960px) {
  .footer-heading {
    padding-top: 10% !important;
  }

  .follow {
    margin: -42px 49px 15px 0px !important;
    padding-left: 40px;
    font-size: 16px;
    color: #000047;
    line-height: 24px;
  }
}

@media only screen and (min-width:576px) and (max-width:802px) {
  .footer-text p {
    font-size: 14px !important;
    margin-top: 4% !important;
  }

  .footer-text1 p {
    font-size: 14px !important;
    /* margin-top: 9% !important; */
  }

  .footer-col-second li a {
    font-size: 14px;
  }
}

@media only screen and (min-width:1500px) and (max-width: 2000px) {
  .copyright p {
    margin-left: 4% !important;
  }

  .copyright2 p {
    margin-left: 26% !important;
  }
}

@media only screen and (min-width:1400px) and (max-width: 1500px) {
  .terms p {
    margin-right: 9% !important;
  }

  .copyright2 p {
    margin-left: 26% !important;
  }

  .copyright p {
    margin-left: 4% !important;
  }
}

@media only screen and (min-width:1300px) and (max-width: 1400px) {
  .terms p {
    margin-right: 8% !important;
  }

  .copyright2 p {
    margin-left: 25% !important;
  }

  .copyright p {
    margin-left: 4% !important;
  }
}

@media only screen and (min-width:1200px) and (max-width: 1300px) {
  .terms p {
    margin-right: 7% !important;
  }

  .copyright2 p {
    margin-left: 24% !important;
  }

  .copyright p {
    margin-left: 4% !important;
  }
}


@media only screen and (min-width:1100px) and (max-width: 1200px) {

  .terms p {
    margin-right: 10% !important;
  }

  .footer .footer-text p {
    font-size: 14px !important;
  }

  .copyright2 p {
    margin-left: 21% !important;
  }

  .copyright p {
    margin-left: 5% !important;
  }
}

@media only screen and (min-width:1000px) and (max-width: 1100px) {

  .terms p {
    margin-right: 6% !important;
  }

  .copyright2 p {
    margin-left: 20% !important;
  }

  .copyright p {
    margin-left: 4% !important;
  }
}

@media only screen and (min-width:950px) and (max-width: 1000px) {
  .copyright2 p {
    margin-left: 16% !important;
  }

  .copyright p {
    margin-left: 4% !important;
  }
}


@media only screen and (min-width:900px) and (max-width: 950px) {
  .copyright2 p {
    margin-left: 13% !important;
  }

  .copyright p {
    margin-left: 4% !important;
  }
}

@media only screen and (min-width:850px) and (max-width: 900px) {

  .copyright2 p {
    margin-left: 12% !important;
  }

  .copyright p {
    margin-left: 4% !important;
  }
}

@media only screen and (min-width:800px) and (max-width: 850px) {

  .copyright2 p {
    margin-left: 9% !important;
  }

  .copyright p {
    margin-left: 4% !important;
  }
}

@media only screen and (min-width:750px) and (max-width: 800px) {

  .copyright2 p {
    margin-left: 6% !important;
  }

  .copyright p {
    margin-left: 4% !important;
  }
}

@media only screen and (min-width:700px) and (max-width: 750px) {

  .copyright2 p {
    margin-left: 5% !important;
  }

  .copyright p {
    margin-left: 5% !important;
  }
}

@media only screen and (min-width:650px) and (max-width: 700px) {

  .copyright2 p {
    margin-left: 3% !important;
  }

  .copyright p {
    margin-left: 5% !important;
  }
}


@media only screen and (min-width:600px) and (max-width: 650px) {

  .copyright2 p {
    margin-left: 0% !important;
  }

  .copyright p {
    margin-left: 5% !important;
  }
}

@media only screen and (min-width:550px) and (max-width: 576px) {
  .copyright2 p {
    margin-left: 29% !important;
  }

  .copyright p {
    margin-left: 12% !important;
  }
}

@media only screen and (min-width:500px) and (max-width: 550px) {
  .copyright2 p {
    margin-left: 29% !important;
  }
}