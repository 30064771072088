@font-face {
    font-family: "newPoppins";
    src: url("../assets/fonts/Poppins-Light.ttf");
}

.fc-event {
    /* width: 90px !important; */
    border: none !important;
}

.fc-daygrid-body.fc-daygrid-body-unbalanced {
    // width: 500px !important;
    width: 100% !important;
}

.fc-view-harness.fc-view-harness-active,
.fc-header-toolbar.fc-toolbar {
    // width: 600px;
    width: 100% !important;
}

.table.fc-col-header {
    // width: 600px !important;
    width: 100% !important;
}

.fc-event-title.fc-sticky {
    /* color: #817EFF !important;
    background-color: #817EFF !important;
    width: 15px;
    height: 15px;
    margin-left: 15px;
    border-radius: 50px;
    margin-top: 7px; */
    color: #817eff !important;
    background-color: #817eff !important;
    width: 35px;
    height: 35px;
    position: absolute !important;
    /* margin-left: 15px; */
    border-radius: 50px;
    margin-top: 7px;
    /* top: 0 !important; */
    left: 24px;
    top: -42px;
    opacity: 0.5;
}

.cal {
    background-color: white !important;
}

.cal tbody,
td,
tfoot,
th,
thead,
tr {
    border: none !important;
}

table.fc-scrollgrid.fc-scrollgrid-liquid {
    border: none !important;
}

div[class^="fc"] {
    background: #ffffff !important;
    font-family: newPoppins;
    font-weight: 600;
}

a.fc-col-header-cell-cushion {
    display: block !important;
    text-decoration: none;
    color: #817eff;
    background-color: white !important;
    padding: 10px 4px !important;
}

.fc-daygrid-day-top a {
    background-color: white !important;
    text-decoration: none;
    margin-right: auto;
    margin-left: auto;
    color: black;
}

.fc.fc-media-screen.fc-direction-ltr.fc-theme-standard,
.fc-daygrid-day-top,
.fc-daygrid-day-events {
    background-color: white !important;
}

.fc .fc-toolbar-title {
    background-color: #ffffff !important;
    font-size: 1.5rem;
    margin-left: 15px;
    color: #817eff !important;
    font-weight: 600;
    font-family: newPoppins;
}

.fc .fc-button-group > .fc-button {
    position: relative;
    flex: 1 1 auto;
    background-color: white;
    border: none;
    font-size: 20px;
    color: black;
    margin-right: 15px;
    padding: 0;
    border: none !important;
}

.fc .fc-button .fc-icon {
    vertical-align: middle;
    font-size: 1.8em;
    background-color: white;
}

.fc .fc-button-primary:not(:disabled):active {
    background-color: white;
    border: none;
    font-size: 20px;
    color: black;
}

/* .fc-daygrid-day-top a:hover {
    background-color: #817EFF !important;
    width: 32px;
    height: 32px;
    color: white;
    text-align: center;
    border-radius: 50px;
} */

.fc .fc-button:disabled {
    opacity: 0.5;
}

.fc .fc-button {
    color: #817eff !important;
    border: 2px solid #817eff !important;
    background-color: white !important;
    font-weight: 600;
}

.fc .fc-button-primary:focus {
    box-shadow: 0 0 0 0.2rem rgb(76 91 106 / 0%) !important;
}

button.fc-today-button.fc-button.fc-button-primary {
    text-transform: capitalize;
}

@media only screen and (max-width: 700px) {
    .fc-daygrid-body.fc-daygrid-body-unbalanced {
        width: 200px !important;
    }

    .fc-view-harness.fc-view-harness-active,
    .fc-header-toolbar.fc-toolbar {
        width: 290px;
    }

    .table.fc-col-header {
        width: 300px !important;
    }

    .calendar-container {
        width: 100% !important;
    }

    .calenderh3 {
        margin: 0 !important;
        font-size: 15px !important;
    }

    .fc-toolbar-title {
        font-size: 15px !important;
        margin: 0 !important;
    }

    .fc .fc-button .fc-icon {
        font-size: 1.3em;
    }

    .ServicesTagbusiness {
        padding: 0 !important;
        margin: 0 !important;
        font-size: 28px !important;
        margin-top: 25px !important;
    }

    .ServicesParagraphbusiness {
        padding: 0 !important;
        margin: 0 !important;
    }

    .ServicesParagraphbusiness p {
        padding: 0 15px !important;
        text-align: justify;
    }

    .fc-event-title.fc-sticky {
        /* color: #817EFF !important;
        background-color: #817EFF !important;
        width: 15px;
        height: 15px;
        margin-left: 15px;
        border-radius: 50px;
        margin-top: 7px; */
        color: #817eff !important;
        background-color: #817eff !important;
        width: 35px;
        height: 35px;
        position: absolute !important;
        /* margin-left: 15px; */
        border-radius: 50px;
        margin-top: 7px;
        /* top: 0 !important; */
        left: 2px;
        top: -42px;
        opacity: 0.5;
    }

    .fc.fc-media-screen.fc-direction-ltr.fc-theme-standard {
        height: 500px !important;
    }

    .ContactMap.leaflet-container.leaflet-touch.leaflet-retina.leaflet-fade-anim.leaflet-grab.leaflet-touch-drag.leaflet-touch-zoom {
        height: 400px !important;
    }
}
