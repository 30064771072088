@font-face {
    font-family: "newBelgro";
    src: url("./assets/fonts/Belgro.ttf");
}

.CookieConsent {
    align-items: baseline;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    left: 0px;
    position: fixed;
    width: 90% !important;
    background-color: #ffff !important;
    z-index: 999;
    bottom: 0px;
    border: 1px solid #ddd !important;
    margin-left: 5% !important;
    margin-bottom: 30px !important;
    font-family: "newPoppins" !important;

    .Text{
        background-color: #ffff;
        color: black;
        font-size: 15px;
    }
    span{
        background-color: #ffff;
        cursor: pointer;
    }

    div{
        background-color: #ffff !important;
        
    }

    button{
       background-color: #817EFF !important;
       padding: 10px !important;
       color: #ffff !important;
       font-size: 13px !important;
    }
}

.Popup_Wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;

    .Popup_Inner_Wrapper {
        width: 1000px;
        background-color: #ffff;
        padding: 10px;
        box-shadow: 0 0 11px rgba(0, 0, 0, 0.9);
    }

}

@font-face {
    font-family: "newPoppins";
    src: url("./assets/fonts/Poppins-Light.ttf");
}